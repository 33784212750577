import { render, staticRenderFns } from "./AlertConfiguration.vue?vue&type=template&id=5b451bc2&scoped=true&"
import script from "./AlertConfiguration.vue?vue&type=script&lang=js&"
export * from "./AlertConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./AlertConfiguration.vue?vue&type=style&index=0&id=5b451bc2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b451bc2",
  null
  
)

export default component.exports